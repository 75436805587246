<template>
    <a-card
    :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}"
    >
    <a-form @submit="handleSubmit">
        <a-checkbox-group @change="productSelected" style="display:inline">

        <a-table :columns="urlcolumn" :data-source="urldata" :pagination="true">
            <template slot="productimg" slot-scope="avatar">
				<a-avatar shape="square" :src="avatar" />
			</template>
            <template slot="chbox" slot-scope="itemurl">
                <a-checkbox :value="itemurl"></a-checkbox>
            </template>
        </a-table>
        </a-checkbox-group>
          <a-button type="primary" html-type="submit" icon="save">Save</a-button>
          <a-space></a-space>
          <a-button type="secondary" icon="close" style="margin-left:10px" @click="backtoproductlist">Cancel</a-button>
    </a-form>
    </a-card>
</template>
<script>
    import {getitem, aisuggestion, multiurl} from '@/api/product'
    export default ({
        props: {
            productid:String
        },
        data() {
            return {
                product:{},
                urldata: [],
                selectedProducts:[],
                urlcolumn: [
                    {dataIndex:'link', key:'itemurl', scopedSlots: { customRender: 'chbox' }},
                    {dataIndex:'cse_image.src', scopedSlots: { customRender: 'productimg' }},
                    {title:'Name', dataIndex:'product.name'},
                    {title:'Url',dataIndex:'link'},
                ],
                form: this.$form.createForm(this,{name: 'productsuggestion'})
            }
        },
        mounted(){
            getitem(this.productid).then(data=>{
                this.product = data;
                this.urldata = [];
                if (data.urls){
                    for(var i=0;i<data.urls.length;i++)
                        this.urldata.push({item:data.urls[i]});
                }
                var testq = this.product.uniqueid;
                if (!testq)
                    testq=this.product.name;
                aisuggestion({q:testq}).then(products=>{
                    this.urldata = products;
                })
            })
        },
        methods: {
            addurl(){
                this.$router.push({path:'/addurl',query:{productid:this.productid}});
            },
            aiproduct(){
                console.log(this.product.uniqueid)
            },
            backtoproductlist(){
                this.$router.go(-1);
            },
            productSelected(values){
                this.selectedProducts = values;
            },
            handleSubmit(e){
                e.preventDefault();
                this.form.validateFields((err,values)=>{
                    if (!err){
                        var indata={productid:this.productid, urls:this.selectedProducts};
                        multiurl(indata).then(data=>{
                            this.$router.go(-1);
                        })
                    }
                });
            }
        }
    });
</script>